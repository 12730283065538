import React from 'react'
import LeftBar from '../Components/LeftBar'
import NavBar from '../Components/NavBar'
import DashComp from './DashComp'
import './Dash.css'
import AdminTrackComp from './AdminTrackComp'


function AdminTrack() {
  return (
    <div className='contentMain'> 
      <LeftBar/>
      <div className='theRightOne'> 
        <div className='DashTop'>
          <h1>Dashboard</h1>
          <NavBar/>
        </div>

        <div className='dataComp'>

            <AdminTrackComp/>

        </div>
     </div>
     </div>
  )
}

export default AdminTrack