import React from 'react'
import './Dash.css'
import NavBar from '../Components/NavBar'
import LeftBar from '../Components/LeftBar'
import LogisticsOrdersComp from './LogisticsOrdersComp'

function LogisticsOrders() {
  return (
<div className='contentMain'> 
        <LeftBar/>
        <div className='theRightOne'> 
          <div className='DashTop'>
            <h1>Logistics Orders</h1>
            <NavBar/>
          </div>
          <LogisticsOrdersComp/>
          
        </div>    
    </div>
  )
}

export default LogisticsOrders