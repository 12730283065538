import React from 'react'
import NavBar from '../../Components/NavBar'
import '../Dash.css'
import ClientLeft from '../../Components/ClientLeft'
import PlaceorderComp from './PlaceorderComp'
import NavBarClient from '../../Components/NavBarClient'

function PlaceAnOrder() {
  return (
    <div className='contentMain'> 
    <ClientLeft/>
    <div className='theRightOne'> 
      <div className='DashTop'>
        <h1>Place an Order</h1>
        <NavBarClient/>
      </div>

      <div className='dataComp'>

          <PlaceorderComp/>

      </div>
   </div>
   </div>
  )
}

export default PlaceAnOrder