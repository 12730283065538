import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import { app } from '../firebase.js';
import { getFirestore } from 'firebase/firestore';
import { collection, orderBy, query, getDocs,deleteDoc,where,doc } from 'firebase/firestore';
import swal from 'sweetalert';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate, useLocation } from 'react-router-dom';

function LogisticsOrdersComp() {

    let navigate = useNavigate();
    const [studentsList, setStudentList] = useState([]);
    const db = getFirestore(app);
  
  
    //fetch all menu items
    useEffect(() => {     
              const fetchData = async () => {  
                const q = query(collection(db, 'Orders'), orderBy('timestamp', 'desc'));
              let studentsItem = [];
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                  studentsItem.push({ id: doc.id, ...doc.data() });
                  setStudentList([...studentsItem]);   
              });
      
          
          };
      fetchData();
      //end magic
  
      }, []);
      //end fetching menu items
  
  
    function viewCustomer(documentId) {
      // Implement your viewCustomer function here
      navigate('/view-order', { state: { myData: documentId } });
    }

    function deleteEntry(docid){

      const q = query(collection(db, "Orders"), where("documentId", "==", docid));
        getDocs(q).then((QuerySnapshot)=>{
            QuerySnapshot.forEach((thdoc)=>{
                let itemname = thdoc.data().transactionRef;
                console.log(itemname)

                swal({
                  title: `Delete Order`,
                  text: `Are you sure you want to delete order with Ref.No ${itemname} ?`,
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })

                .then((willDelete) => {
                  if (willDelete) {
                    deleteDoc(doc(db, "Orders", docid))
                    .then(()=>{
                      swal("Deleted", "" , "success");
                      swal(`${itemname} has been deleted!`, {
                        icon: "success",
                      }).then(()=>{
                        window.location.reload();
                      })
                      
                    })

                  } else {
                    swal("Cancelled");
                  }
                });                    
            })
        })

    }
  
    return (
      <div className="dataComp">
        <div className="dataCompTop">
          <h5>All Orders</h5>
  
          <div className="searchContainer">
            <FontAwesomeIcon icon={faSearch} className="searchIcon" />
            <input type="text" placeholder="Type to search" />
            <button>Go</button>
          </div>
        </div>
  
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Date</th>
              <th>Order Type</th>
              <th>Status</th>
              <th className='logWidth1'>Location From</th>
              <th className='logWidth2'>Location To</th>
              <th>View</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {studentsList.map((mystudent) => (
              <tr key={mystudent.id}>              
                <td>{mystudent.timestamp.toDate().toLocaleString()}</td>
                <td>{mystudent.orderType}</td>
                <td>
                    <span
                        style={{
                            color:
                              mystudent.status === 'Pending'
                                ? 'red' // Set blue color for 'pending' status
                                : mystudent.status === 'Ongoing'
                                ? 'royalblue' // Set green color for 'Ongoing' status
                                : mystudent.status === 'Delivered'
                                ? 'green' // Set red color for 'delivered' status (or any other status)
                                : 'black', // Set a default color (e.g., black) for any other status
                          }}
                        >
                        {mystudent.status}
                    </span>
                </td>
                <td>{mystudent.locationFrom}</td>
                <td>{mystudent.locationTo}</td>   
                <td>
                  <button
                    className="btn-sm btn btn-warning"
                    onClick={() => viewCustomer(mystudent.documentId)}
                  >
                    View Order
                  </button>
                </td>

                <td>
                  <button
                    className="btn-sm btn btn-danger"
                    onClick={() => deleteEntry(mystudent.documentId)}
                  >
                    Delete
                  </button>
                </td>

              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
  
export default LogisticsOrdersComp