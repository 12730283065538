import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import { app } from '../../firebase.js';
import { getFirestore } from 'firebase/firestore';
import { collection, orderBy, query, where, getDocs } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { useNavigate, useLocation } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import swal from 'sweetalert';


function MyOrdersComp() {
  const [studentsList, setStudentList] = useState([]);
  const db = getFirestore(app);
  const auth = getAuth();
  let navigate = useNavigate();


  useEffect(() => {
    const fetchData = async () => {
      // Assuming you have the user's ID available
      const user = auth.currentUser;
      const userId = user ? user.uid : null;

      if (userId) {
        const q = query(
          collection(db, 'Orders'),
          where('userId', '==', userId), // Check the actual field name in your database
          orderBy('timestamp', 'desc')
        );

        try {
          let orders = [];
          const querySnapshot = await getDocs(q);

          querySnapshot.forEach((doc) => {
            orders.push({ id: doc.id, ...doc.data() });
          });

          // Set your state with the fetched orders
          setStudentList(orders);
        } catch (error) {
          console.error('Error fetching orders:', error);
        }
      }
    };

    fetchData();
  }, []); // Make sure to include any dependencies if needed


  


  function viewCustomer(documentId) {
    // Implement your viewCustomer function here
   // navigate('/client/vieworder', { state: { myData: documentId } });
   
  }



  function trackDriver(driver){

    if(driver == undefined){
      swal("Delivery Pending", "Your order is still pending",  "warning");
    }else{

      navigate('/client/trackdriver', { state: { myData: driver } });
    // navigate('/client/trackdriver');
    }

  }

  function viewCustomer(documentId) {
    // Implement your viewCustomer function here
    navigate('/client/vieworder', { state: { myData: documentId } });
  }

  return (
    <div className="dataComp">
      <div className="dataCompTop">
        <h5>All Orders</h5>

        <div className="searchContainer">
          <FontAwesomeIcon icon={faSearch} className="searchIcon" />
          <input type="text" placeholder="Type to search" />
          <button>Go</button>
        </div>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>Order Type</th>
            <th>Status</th>
            <th className='logWidth1'>Location From</th>
            <th className='logWidth2'>Location To</th>
            <th>View</th>
            <th>Track Driver</th>
          </tr>
        </thead>
        <tbody>
          {studentsList.map((mystudent) => (
            <tr key={mystudent.id}>              
              <td>{mystudent.timestamp.toDate().toLocaleString()}</td>
              <td>{mystudent.orderType}</td>
              <td>
                  <span
                      style={{
                          color:
                            mystudent.status === 'Pending'
                              ? 'red' // Set blue color for 'pending' status
                              : mystudent.status === 'Ongoing'
                              ? 'royalblue' // Set green color for 'Ongoing' status
                              : mystudent.status === 'Delivered'
                              ? 'green' // Set red color for 'delivered' status (or any other status)
                              : 'black', // Set a default color (e.g., black) for any other status
                        }}
                      >
                      {mystudent.status}
                  </span>
              </td>
              <td>{mystudent.locationFrom}</td>
              <td>{mystudent.locationTo}</td>   
              <td>
                <button
                  className="btn-sm btn btn-primary"
                  onClick={() => viewCustomer(mystudent.documentId)}
                >
                  View Order
                </button>
              </td>
              <td>
                <button
                  className="btn-sm btn btn-dark"
                  onClick={() => trackDriver(mystudent.driver)}
                >
                  Track Progress
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
export default MyOrdersComp