import React, { useRef, useState } from 'react'
import './Dash.css'
import NavBar from '../Components/NavBar'
import LeftBar from '../Components/LeftBar'
import CustomerComp from './CustomerComp'
import ExpCustomerComp from './ExpCustomerComp'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch} from '@fortawesome/free-solid-svg-icons'
import { faAdd} from '@fortawesome/free-solid-svg-icons'

function Customers() {

  const [showProgress, setShowProgress] = useState(true);
  const [showSignInbtn, setShowSignInbtn] = useState(false);

  const [showWineBtn, setshowWineBtn] = useState(true);
  const [showFoodBtn, setshowFoodBtn] = useState(true);

  const [showWineBtn2, setshowWineBtn2] = useState(false);
  const [showFoodBtn2, setshowFoodBtn2] = useState(false);


  function showWine(){

    setShowProgress(true);
    setShowSignInbtn(false)

    setshowFoodBtn2(false)
    setshowFoodBtn(true)
    setshowWineBtn(true)
    setshowWineBtn2(false)


  }

  function showFood(){
    setShowProgress(false);
    setShowSignInbtn(true)
    
    setshowFoodBtn2(true)
    setshowFoodBtn(false)
    setshowWineBtn(false)
    setshowWineBtn2(true)

  }


  return (
<div className='contentMain'> 
        <LeftBar/>
        <div className='theRightOne'> 
          <div className='DashTop'>
            <h1>Subscriptions</h1>
            <NavBar/>
          </div>

          <div className='dataCompTop'>

<div className='mymenu'>
    <h5>Logistics Subcriptions</h5>

   {showWineBtn &&  <button className='wineBtn' onClick={showWine}>Active</button>}
   {showFoodBtn && <button className='foodBtn' onClick={showFood}>Expired</button>}

   {showWineBtn2 &&  <button className='wineBtn2' onClick={showWine}>Active</button>}
   {showFoodBtn2 && <button className='foodBtn2' onClick={showFood}>Expired</button>}
</div>


<div className='searchContainer'>
    <FontAwesomeIcon icon={faSearch} className="searchIcon"/>
    <input type="text" placeholder='Type to search' className='searchAdmin'/>
    <button>Go</button>
</div>
</div>

        {showSignInbtn && 
         <ExpCustomerComp/>
        
        }   
         {showProgress && 
         <CustomerComp/>
         }
        
         

         
          
        </div>    
    </div>
  )
}

export default Customers