import React from 'react'
import NavBar from '../../Components/NavBar'
import '../Dash.css'
import ClientLeft from '../../Components/ClientLeft'
import OrdersComp from './OrdersComp'
import DriverLeft from '../../Components/DriverLeft'

function MyOrdersDriver() {
  return (
    <div className='contentMain'> 
    <DriverLeft/>
    <div className='theRightOne'> 
      <div className='DashTop'>
        <h1>My Orders (Driver)</h1>
        <NavBar/>
      </div>

      <div className='dataComp'>

          <OrdersComp/>

      </div>
   </div>
   </div>
  )
}

export default MyOrdersDriver