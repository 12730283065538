import React from 'react'
import NavBar from '../../Components/NavBar'
import DashComp from '../DashComp'
import '../Dash.css'
import ClientLeft from '../../Components/ClientLeft'
import InvoicesComp from './InvoicesComp'
import NavBarClient from '../../Components/NavBarClient'

function Invoices() {
  return (
    <div className='contentMain'> 
      <ClientLeft/>
      <div className='theRightOne'> 
        <div className='DashTop'>
          <h1>Deliveries</h1>
          <NavBarClient/>
        </div>

        <div className='dataComp'>

            <InvoicesComp/>

        </div>
     </div>
     </div>
  )
}

export default Invoices