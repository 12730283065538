import React from 'react'
import LeftBar from '../Components/LeftBar'
import NavBar from '../Components/NavBar'
import DashComp from './DashComp'
import './Dash.css'
import DeliveriesComp from './DeliveriesComp'
import DelRequestComp from './DelRequestComp'

function DelRequest() {
  return (
    <div className='contentMain'> 
    <LeftBar/>
    <div className='theRightOne'> 
      <div className='DashTop'>
        <h1>Delivery Requests</h1>
        <NavBar/>
      </div>

      <div className='dataComp'>

          <DelRequestComp/>

      </div>
   </div>
   </div>
  )
}

export default DelRequest