import React, { useRef, useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { app } from '../../firebase.js';
import { getFirestore } from '@firebase/firestore';
import { collection, query, getDocs, where, getDoc , setDoc, orderBy, deleteDoc} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";

import swal from 'sweetalert';
import { useNavigate, useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';

import { doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";


import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import ImagePlaceholder from '../../Images/defprofile.png'

function ViewOrderComp() {
  const navigate = useNavigate();
  const location = useLocation();
  const db = getFirestore(app);
  const auth = getAuth();

  const [FullName, setFullName] = useState()
  const [thePhone, setPhone] = useState()

  const [locationTo, setlocationTo] = useState()
  const [locationFrom, setlocationFrom] = useState()

  const [NlocationFromDescription,setNlocationFromDescription ] = useState()
  const [NlocationToDescription,setNlocationToDescription ] = useState()

  const [NlocationFromContactPerson, setNlocationFromContactPerson] = useState();
  const [nlocationFromContactPhone, setnlocationFromContactPhone] = useState();
  const [NlocationToRecepientName, setNlocationToRecepientName] = useState();
  const [NlocationToRecepientPhone, setNlocationToRecepientPhone] = useState();

  const [orderType, setorderType] = useState();
  const [NwhatIsBeingDelivered, setNwhatIsBeingDelivered] = useState();
  const [packageDesc, setpackageDesc] = useState();
  const [numberOfItems, setnumberOfItems] = useState();
  const [totalWeight, settotalWeight] = useState();
  const [receivedBy, setreceivedBy] = useState();
  const [receivedByPhone, setreceivedByPhone] = useState();

  const [Driver,setDriver] = useState()

  // Receive data from the previous page
  useEffect(() => {
      const data = location.state?.myData;
      setMyData(data);
  }, [location.state]);

  const [myData, setMyData] = useState(null); // State to store the received data
  
  console.log(myData)

      ////



  useEffect(() => {
      // Fetch driver's location from Firebase when myData changes
      const fetchData = async () => {
        if (myData) {
          try {
            const querySnapshot = await getDocs(query(collection(db, 'Orders'), where('documentId', '==', myData)));
  
            querySnapshot.forEach(async (doc) => {
              const driver = doc.data().driver;  
              const locationTo = doc.data().locationTo;
              const locationFrom = doc.data().locationFrom;

              setDriver(driver)

              const NlocationFromDescription = doc.data().NlocationFromDescription;
              const NlocationToDescription = doc.data().NlocationToDescription;
              const NlocationFromContactPerson = doc.data().NlocationFromContactPerson;
              const nlocationFromContactPhone = doc.data().nlocationFromContactPhone;

              const NlocationToRecepientName = doc.data().NlocationToRecepientName;
              const NlocationToRecepientPhone = doc.data().NlocationToRecepientPhone;

              const orderType = doc.data().orderType;
              const NwhatIsBeingDelivered = doc.data().NwhatIsBeingDelivered;
              const packageDesc = doc.data().packageDesc;
              const numberOfItems = doc.data().numberOfItems;
              const totalWeight = doc.data().totalWeight;
              const receivedBy = doc.data().receivedBy;
              const receivedByPhone = doc.data().receivedByPhone;


              setorderType(orderType)
              setNwhatIsBeingDelivered(NwhatIsBeingDelivered)
              setpackageDesc(packageDesc)
              setnumberOfItems(numberOfItems)
              settotalWeight(totalWeight)
              setreceivedBy(receivedBy)
              setreceivedByPhone(receivedByPhone)

              



              setlocationTo(locationTo)
              setlocationFrom(locationFrom)

              setNlocationFromDescription(NlocationFromDescription);
              setNlocationToDescription(NlocationToDescription);

              setNlocationFromContactPerson(NlocationFromContactPerson);
              setnlocationFromContactPhone(nlocationFromContactPhone)
              setNlocationToRecepientPhone(NlocationToRecepientPhone)
              setNlocationToRecepientName(NlocationToRecepientName)

              


            if(driver != undefined){
              const j = query(collection(db, "drivers"),where("userId", "==", driver));
              getDocs(j).then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {

                    let full_name = doc.data().full_name;
                    const userphone = doc.data().userphone

                    setFullName(full_name)
                    setPhone(userphone)
                  
           
                  });
                });
            }else{

              setFullName("Pending")
              setPhone("Pending")
            }
        




            });
          } catch (error) {
            console.error('Error fetching data from Firebase:', error);
          }
        }
      };
  
      // Initial fetch
      fetchData();
    }, [myData, db]);
  
    ///

    function ViewWybill(){
      const sendData = myData;

      if(Driver!= undefined){
          navigate('/view-waybill', { state: { myData: sendData } });
      }else{
          swal("Pending", "This trip is still pending", "error");
      }
      
    }



return (
  <div className='viewOrderComp'>

      <div className='viewLeft'>
          <h6>Moving From</h6>
          <Alert >
              <p>{locationFrom}</p>
              <p>{NlocationFromDescription}</p>
              <p>Contact Person: {NlocationFromContactPerson}</p>
              <p>Contact Phone: {nlocationFromContactPhone}</p>
          </Alert>

          <h6>Moving To</h6>
          <Alert >
              <p>{locationTo}</p>
              <p>{NlocationToDescription}</p>
              <p>Recepient Name: {NlocationToRecepientName}</p>
              <p>Recepient Phone: {NlocationToRecepientPhone}</p>
          </Alert>

          <h6>Delivery</h6>
          <Alert >
              <p>Order Type: {orderType}</p>
              <p>What is being delivered: {NwhatIsBeingDelivered}</p>
              <p>Package Desc: {packageDesc}</p>
              <p>number Of Items: {numberOfItems}</p>
              <p>Total Weight: {totalWeight}</p>
          </Alert>

          <div className='waybillsCont'>

              <div>
                  <p>Received By {receivedBy}</p>
                  <p>{receivedBy}'s Phone: {receivedByPhone}</p>
              </div>

              <button className='btn btn-primary' onClick={ViewWybill}>View waybill</button>
          </div>
          
      </div>

      <div className='viewRight'>
          <h6>Driver</h6>

          <div className='viewProfCont'>
              <div className='roundProfileView'>

                  <img src={ImagePlaceholder} alt="" />
              </div>

              <h5 className='roundH5'>{FullName}</h5>
              <p>{thePhone}</p>

              {/* <div>
                  <button >Reassign Order</button>
                  <button >Track Driver</button>
              </div> */}

          </div>


      </div>


  </div>

  )
}

export default ViewOrderComp