import React from 'react'
import './Leftbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser} from '@fortawesome/free-solid-svg-icons'
import { faChartPie} from '@fortawesome/free-solid-svg-icons'
import { faLineChart} from '@fortawesome/free-solid-svg-icons'
import { faClipboardCheck} from '@fortawesome/free-solid-svg-icons'
import { faThLarge} from '@fortawesome/free-solid-svg-icons'
import { faTruck} from '@fortawesome/free-solid-svg-icons'
import { faArrowRightFromBracket} from '@fortawesome/free-solid-svg-icons'
import { faFaceSmile} from '@fortawesome/free-solid-svg-icons'
import { faTag} from '@fortawesome/free-solid-svg-icons'
import { faMoneyCheck} from '@fortawesome/free-solid-svg-icons'
import { faFaceAngry} from '@fortawesome/free-solid-svg-icons'
import { faBagShopping} from '@fortawesome/free-solid-svg-icons'
import { faUsers} from '@fortawesome/free-solid-svg-icons'
import { faPenNib} from '@fortawesome/free-solid-svg-icons'
import { faMugHot} from '@fortawesome/free-solid-svg-icons'
import { faChartSimple} from '@fortawesome/free-solid-svg-icons'
import { faClipboard} from '@fortawesome/free-solid-svg-icons'
import { faBriefcase} from '@fortawesome/free-solid-svg-icons'
import { faAddressCard} from '@fortawesome/free-solid-svg-icons'
//routers
import {useNavigate } from "react-router-dom";
import {Link } from "react-router-dom";
import {NavLink } from "react-router-dom";


import {app} from '../firebase.js'
import { getAuth, signOut } from "firebase/auth";

function ClientLeft() {
    let navigate = useNavigate();


    function logoutUser(){
        const auth = getAuth();
        signOut(auth).then(() => {
        // Sign-out successful.
            navigate("/")  
        }).catch((error) => {

        });
    }


  return (
    <div className='leftbar'>

    <div className='menuContainer'>

        <div className='logocontainer'>
            <h3>Data Rush</h3>
        </div>

        <br /> <br /> <br />

        
        <div className='leftLinkCont d-flex align-items-center' >
            <NavLink to="/client/dashboard" className='leftNavLinks ' >
                <FontAwesomeIcon icon={faThLarge} className="laftNavIcon"/>
                Dashboard
            </NavLink>
        </div>

        <hr />

        <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/client/place-an-order" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faTruck} className="laftNavIcon"/>
                    Place an Order
                </NavLink>
            </div>

            <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/client/all-orders" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faClipboardCheck} className="laftNavIcon"/>
                    My Orders
                </NavLink>
            </div>

            <hr />

            <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/client/invoices" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faTag} className="laftNavIcon"/>
                    Deliveries
                </NavLink>
            </div>

       

    </div>

    <div className='footerMenu'>
        <div className='logoutMenu' onClick={logoutUser}>
            <div className='leftLinkCont d-flex align-items-center'>
                <div  className='leftNavLinks logoutbtn'>
                    <FontAwesomeIcon icon={faArrowRightFromBracket} className="laftNavIcon"/>
                    Logout
                </div>
            </div>
        </div>

    </div>





</div>
  )
}


export default ClientLeft