import React, { useRef, useState, useEffect } from 'react';
import { app } from '../firebase.js';
import { getFirestore } from 'firebase/firestore';
import { collection, orderBy, query, getDocs } from 'firebase/firestore';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle} from '@fortawesome/free-solid-svg-icons'

function NextDayPackage() {

  const [studentsList, setStudentList] = useState([]);
  const db = getFirestore(app);


  //fetch all menu items
    useEffect(() => {     
      const fetchData = async () => {  
          const q = query(collection(db, 'nextDayDeliveries'), orderBy('PackagePrice'));
          let studentsItem = [];
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
              studentsItem.push({ id: doc.id, ...doc.data() });
              setStudentList([...studentsItem]);   
          });
      };
    fetchData();
    //end magic

    }, []);
    //end fetching menu items

  return (
    <div className='dataComp1'>

    {studentsList.map((mystudent) => (
      <section className='logisticsPricing' key={mystudent.id}>
        <h4>{mystudent.PackageName}</h4>
        <h2>KES. {mystudent.PackagePrice} / <span className='pricingSpan'>month</span></h2>
        <p>{mystudent.Description} </p>

        <p className='pricongP'><FontAwesomeIcon icon={faCheckCircle} className="checkIcon"/> {mystudent.featureOne}</p>
        <p className='pricongP'><FontAwesomeIcon icon={faCheckCircle} className="checkIcon"/> {mystudent.featureTwo}</p>
        <p className='pricongP'><FontAwesomeIcon icon={faCheckCircle} className="checkIcon"/> {mystudent.featureThree}</p>
        <p className='pricongP'><FontAwesomeIcon icon={faCheckCircle} className="checkIcon"/> {mystudent.featureFour}</p>
        <p className='pricongP'><FontAwesomeIcon icon={faCheckCircle} className="checkIcon"/> {mystudent.featureFive}</p>

        <button>Update Package</button>

      </section>
    ))}


    </div>
  )
}

export default NextDayPackage