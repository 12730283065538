import React from 'react'
import dashImage from '../Images/dashone.svg'

function DashComp() {
  return (
    <div>

        <div className='cardOne'>
            
        </div>

        <div className='cardTwo'>

        </div>


        <div className='cardThree'>

        </div>

        <div className='cardFour'>

        </div>

        <div className='cardFive'>

        </div>


    </div>
  )
}

export default DashComp