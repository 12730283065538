import React, { useRef, useState } from 'react'
import './Dash.css'
import NavBar from '../Components/NavBar'
import LeftBar from '../Components/LeftBar'
import NextDayPackage from './NextDayPackage'
import OnDemandPackage from './OnDemandPackage'
import RecurrentPackage from './RecurrentPackage'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch} from '@fortawesome/free-solid-svg-icons'
import { faAdd} from '@fortawesome/free-solid-svg-icons'

function LogisticsPricing() {
    const [showOnDemand, setshowOnDemand] = useState(true);
    const [showNextDay, setshowNextDay] = useState(false);
    const [showReccurrent, setshowReccurrent] = useState(false);
  

    const [shownOnDemand, setshownOnDemand] = useState(true);
    const [shownNextDay, setshownNextDay] = useState(true);
    const [shownReccurring, setshownReccurring] = useState(true);
  

    const [buttonClassName, setButtonClassName] = useState('foodBtn1');
    const [buttonClassName2, setButtonClassName2] = useState('foodBtn1');
    const [buttonClassName1, setButtonClassName1] = useState('wineBtn1');


    

  
    function funOndemand(){

        setshowOnDemand(true)
        setshowNextDay(false)
        setshowReccurrent(false)

        setButtonClassName('foodBtn1');
        setButtonClassName1('wineBtn1')
         setButtonClassName2('foodBtn1')


  
    }
  
    function funNextday(){
        setshowOnDemand(false)
        setshowNextDay(true)
        setshowReccurrent(false)

        setButtonClassName('wineBtn1');
        setButtonClassName1('foodBtn1')
        setButtonClassName2('foodBtn1')



    }

    function funReccur(){
        setshowOnDemand(false)
        setshowNextDay(false)
        setshowReccurrent(true)

        setButtonClassName2('wineBtn1')
        setButtonClassName('foodBtn1');
        setButtonClassName1('foodBtn1')

            

    }

  
  
    return (
  <div className='contentMain'> 
          <LeftBar/>
          <div className='theRightOne'> 
            <div className='DashTop'>
              <h1>Pricing Plan</h1>
              <NavBar/>
            </div>
  
            <div className='dataCompTop'>
  
  <div className='mymenu'>
      <h5>Pricing</h5>
  
     {shownOnDemand &&  <button className={buttonClassName1} onClick={funOndemand}>On Demand Delivery</button>}
     {shownNextDay && <button className={buttonClassName} onClick={funNextday} >Next Day Delivery</button>}
     {shownReccurring && <button className={buttonClassName2} onClick={funReccur}>Reccuring Errands</button>}

     
  </div>
  
  
  <div className='searchContainer'>
      <button>Add a Package</button>
  </div>
  </div>
  
          {showOnDemand && 
          
           <OnDemandPackage/>
          
          }   
           {showNextDay && 
            <NextDayPackage/>
           }

           {showReccurrent && 
           <RecurrentPackage/>
           }
        
           
  
           
            
          </div>    
      </div>
    )
  }
export default LogisticsPricing