import React, { useRef, useState } from 'react'
import './Auth.css'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

//routers
import {useNavigate } from "react-router-dom";
import {Link } from "react-router-dom";

import swal from 'sweetalert';


import { getFirestore } from "firebase/firestore";

import {app} from '../firebase.js'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { collection, query, getDocs, where } from "firebase/firestore";

import { doc, getDoc } from "firebase/firestore";



function Login() {
    let navigate = useNavigate();
    const emailRef = useRef();
    const passwordRef = useRef();
  
    const [showProgress, setShowProgress] = useState(false);
    const [showSignInbtn, setShowSignInbtn] = useState(true);
  
    function loginUser() {
      const email = emailRef.current.value;
      const password = passwordRef.current.value;
  
      setShowProgress(true);
      setShowSignInbtn(false);
  
      const auth = getAuth();
      const db = getFirestore(app);
      
      signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
              const user = userCredential.user;
              const userID = user.uid;
  
              // Check if user is an admin
              const adminQuery = query(collection(db, "admin"), where("userid", "==", userID));
              getDocs(adminQuery).then((adminSnapshot) => {
                  if (!adminSnapshot.empty) {
                      adminSnapshot.forEach((adminDoc) => {
                          const userType = adminDoc.data().role;
                          if (userType === "superAdmin") {
                              navigate("/dashboard");
                          } else {
                              navigate("/dashboard"); // or wherever admins navigate
                          }
                      });
                  } else {
                      // User is not an admin, check if user is a client
                      const clientQuery = query(collection(db, "clients"), where("userId", "==", userID));
                      getDocs(clientQuery).then((clientSnapshot) => {
                          if (!clientSnapshot.empty) {
                              navigate("/client/dashboard");
                          } else {
                              // User is neither an admin nor a client, handle accordingly
                              navigate("/driver/place-an-order"); // or navigate to a page showing appropriate message
                          }
                      }).catch((error) => {
                          console.error("Error checking client collection:", error);
                      });
                  }
              }).catch((error) => {
                  console.error("Error checking admin collection:", error);
              });
          })
          .catch((error) => {
              setShowProgress(false);
              setShowSignInbtn(true);
              const errorCode = error.code;
              const errorMessage = error.message;
              swal("Login Error!", errorMessage, "error");
          });
  }
  
  
  
    return (
      <div className='mainAuth'>
  
          <div className='AuthLeft'>
  
            <div className='authleftCont'>
              <video autoPlay muted loop src="https://res.cloudinary.com/ubunifu/video/upload/v1703272278/pexels-rodnae-productions-7362571_720p_ticojk.mp4"></video>
              <div className='authLeftOpacity'></div>
            </div>
            
  
          </div>
  
          <div className='AuthRight'>
  
                {/* <img src={Logo} alt="" className='logoAuth' /> */}
                <h3>Data Rush</h3>
                <br />
   
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="enter email" ref={emailRef}/>
  
                </Form.Group>
  
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="password" ref={passwordRef} />
                </Form.Group>
  
                {showSignInbtn && 
                <Button variant="primary" type="submit" className='btnLogin' onClick={loginUser}>
                Sign in
                </Button>
                }
  
                {showProgress && 
                    <Button variant="primary" disabled  className='btnLogin'>
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        />
                        <span> Signing in...</span>
                    </Button>
                }
{/*   
                <Link to="/forgot-password" className='signuptext '> Forgot Password?</Link> */}
       
  
          </div>
      </div>
    )
}

export default Login