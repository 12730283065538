import React from 'react'
import NavBar from '../../Components/NavBar'
import '../Dash.css'
import ClientLeft from '../../Components/ClientLeft'
import MyDash from './MyDash'
import NavBarClient from '../../Components/NavBarClient'

function Dashboard() {
  return (
    <div className='contentMain'> 
      <ClientLeft/>
      <div className='theRightOne'> 
        <div className='DashTop'>
          <h1>Dashboard</h1>
          <NavBarClient/>
        </div>

        <div className='dataComp'>

            <MyDash/>

        </div>
     </div>
     </div>
  )
}

export default Dashboard