import React from 'react'
import NavBar from '../../Components/NavBar'
import '../Dash.css'
import ClientLeft from '../../Components/ClientLeft'
import ViewOrderComp from './ViewOrderComp'
import NavBarClient from '../../Components/NavBarClient'

function ViewOrder() {
  return (
<div className='contentMain'> 
      <ClientLeft/>
      <div className='theRightOne'> 
        <div className='DashTop'>
          <h1>View Order</h1>
          <NavBarClient/>
        </div>

        <div className='dataComp'>

            <ViewOrderComp/>

        </div>
     </div>
     </div>
  )
}

export default ViewOrder