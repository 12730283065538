import React from 'react'
import './Dash.css'
import NavBar from '../Components/NavBar'
import LeftBar from '../Components/LeftBar'
import VehiclesComp from './VehiclesComp'

function Vehicles() {
  return (
<div className='contentMain'> 
        <LeftBar/>
        <div className='theRightOne'> 
          <div className='DashTop'>
            <h1>Vehicles</h1>
            <NavBar/>
          </div>
          < VehiclesComp/>
          
        </div>    
    </div>
  )
}

export default Vehicles