import React from 'react'
import LeftBar from '../Components/LeftBar'
import NavBar from '../Components/NavBar'
import './Dash.css'
import ViewOrderComp from './ViewOrderComp'


function ViewOrder() {
  return (
<div className='contentMain'> 
      <LeftBar/>
      <div className='theRightOne'> 
        <div className='DashTop'>
          <h1>View Order</h1>
          <NavBar/>
        </div>

        <div className='dataComp'>

            <ViewOrderComp/>

        </div>
     </div>
     </div>
  )
}

export default ViewOrder