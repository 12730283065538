import React from 'react'
import {Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapLocation} from '@fortawesome/free-solid-svg-icons'
import { faPhone} from '@fortawesome/free-solid-svg-icons'
import { faMessage} from '@fortawesome/free-solid-svg-icons'
import { faTruck} from '@fortawesome/free-solid-svg-icons'
import { faMugHot} from '@fortawesome/free-solid-svg-icons'
import { faMicrochip} from '@fortawesome/free-solid-svg-icons'
import servOneImg from '../Images/download (6).png'
import servTwoImg from '../Images/download (3).png'
import servThreeImg from '../Images/download (4).png'
import servFourImg from '../Images/download (5).png'

function HomePage() {
  return (
    <div>
        <div className='homePage' id='home'>
            <div className='navBar'>
                <h4>Data Rush</h4>

                <ul className='menuPage'>
                    <li><a href="#home">Home</a></li>
                    <li><a href="#about">About Us</a></li>
                    <li><a href="#services">Our Services</a></li>
                    <li><a href="#contact">Contact Us</a></li>
                    <li>
                    <Link to="/login" className='navToHome'>Sign In</Link>
                    </li>
                </ul>

            </div>

            <div className='homeContent'>
                <h1>We Carry Anything
                We Go Anywhere</h1>
                <p>Our passion is what happens after the delivery... We deliver Hope, Opportunity, and Potential!</p>
                <div>
                    <Link to="/login" className='navToHome2'>Login to your Account</Link>
                </div>
            </div>

        </div>

        <section className='section2' id='about'>
            {/* <h3>Why Us?</h3> */}

            <br/>

            <div className='section2Cont'>
                <div className='box1Home'>
                <FontAwesomeIcon icon={ faTruck} className="contactInfo"/>
                    <h5>Fast Moving World of Business</h5>
                    <p>In this fast-moving world of business, you need a fast-moving delivery company. At DATA RUSH SERVICES we collect and deliver your personal/official correspondence to business/clients; letters, parcels, monthly statements, invoices, advertisements fliers, cheques, medical reports, and all other important documents and packages on time, all the time. In addition, we adhere to save your precious time by running your monthly errands of paying Telephone, Electricity, and Water bills. Our goal is to compliment Speed (Rush) with customer care and service.
                    </p>

                </div>
                <div className='box1Home'>
                <FontAwesomeIcon icon={faMugHot} className="contactInfo"/>
                    <h5>Our Services Now and in the Future</h5>
                    <p>Our services cater for picking up consignments from your premises twice or once a day depending on your requirements and delivering them to the recipient at the shortest time possible: we guarantee it. We are also on call for your Urgent/Priority deliveries, which we ably deliver within three hours due to the traffic jams. All you have to do is pick-up the telephone and call us, and we will be at your premises within the shortest time possible.
                    </p> <br /> <br />

                </div>
                <div className='box1Home'>
                <FontAwesomeIcon icon={faMicrochip} className="contactInfo"/>
                    <h5>Ideally Located</h5>
                    <p>We are ideally located in Upper hill Nairobi, which is an ideal focal point to reach any corner of Nairobi. We operate a Fleet of 65 Motorcycles and 20 Vehicles covering the entire City. Our branches offers door-to-door delivery and collection services for mail or parcels leaving or arriving at the each branch. If you have mail or parcels destined for the major towns , call Data Rush Services, we will be happy to receive your call.     
                    </p>

                </div>
            </div>
        </section>


        <section className='aboutHome'>

            <div className='aboutHome1'>

            </div>

            <div className='aboutHome2'>
                <div className='aboutHome2Child'>
                    <h1>About Us</h1>
                    <p>Data Rush Logistics is a premier provider of comprehensive logistics solutions, strategically headquartered in Nairobi, Kenya. Established with a vision to revolutionize the logistics industry in the region, we have garnered a reputation for excellence, reliability, and innovation.</p>
                    <h6>Our Journey</h6>
                    <p>Our journey began with a simple yet powerful idea – to redefine the standards of logistics services in Nairobi. With a team of dedicated professionals driven by passion and a commitment to excellence, Data Rush Logistics was founded. Since our inception, we have embarked on a relentless pursuit of perfection, continuously striving to exceed the expectations of our clients and stakeholders.</p>
                    <Link to="/login" className='navToHome'>Sign In</Link>

                </div>

            </div>

        </section>

        <section className='services' id='services'>

            <h1>Our Services</h1>
            <div className='services1'>
                
                <div>
                    <h3>Last-Mile Delivery</h3>
                    <p>The final leg of the journey is often the most critical, and that's where our last-mile delivery services shine. With a network of reliable partners and cutting-edge logistics technology, we ensure that your goods are delivered to their intended recipients with speed, accuracy, and care. Whether it's doorstep delivery, installation services, or reverse logistics, we handle every aspect of the last-mile process, providing you and your customers with a seamless delivery experience.</p>
                </div>
                <img src={servOneImg} alt="" />

            </div>

            <div className='services1'>
            <img src={servTwoImg} alt="" />
                <div>
                    <h3>Supply Chain Management</h3>
                    <p>At Data Rush Logistics, we understand that effective supply chain management is essential for driving business success. That's why we offer end-to-end supply chain solutions designed to streamline operations, reduce costs, and improve overall efficiency. From procurement and inventory management to order fulfillment and distribution, we leverage advanced technologies and best practices to optimize every stage of the supply chain, allowing you to focus on core business activities and drive growth.</p>
                </div>
                

            </div>

            <div className='services1'>
                
                <div>
                    <h3>Transportation</h3>
                    <p>Our transportation services are not just about moving goods from point A to point B; they're about delivering peace of mind. With a fleet of modern vehicles, advanced tracking systems, and a team of skilled drivers, we ensure that your shipments reach their destination safely and on time, every time. Whether you need express delivery, temperature-controlled transport, or specialized handling, we have the expertise and resources to meet your unique requirements.</p>
                </div>
                <img src={servThreeImg} alt="" />

            </div>

            <div className='services1'>
            <img src={servFourImg} alt="" />
                <div>
                    <h3>Warehousing</h3>
                    <p>Our warehousing solutions are designed to provide you with the flexibility and efficiency you need to optimize your supply chain. Our state-of-the-art facilities are equipped with advanced security systems, climate control options, and inventory management technologies to ensure the safety and integrity of your goods. With customizable storage options, real-time visibility, and seamless integration with our transportation services, we offer a comprehensive warehousing solution tailored to your specific needs.</p>
                </div>
                

            </div>

        </section>

        <section className='contactUsHome' id='contact'>
            <h1>Contact Us</h1>
            <div className='contactMapLocation'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63820.96012512795!2d36.74027639176742!3d-1.2881540322909626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f11a283d51c0d%3A0xc7b9d2f0cbeda746!2sData%20Rush%20Services%20Ltd-Nairobi%20Office!5e0!3m2!1sen!2ske!4v1710513737068!5m2!1sen!2ske"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </section>

        <section className='footerHome'>

            <div className='footerCont'>
                <div>
                    <FontAwesomeIcon icon={faMapLocation} className="contactInfo"/>
                    <h5>ADDRESS</h5>
                    <p>Upperhill, Nairobi</p>

                </div>
                    

                <div>
                <FontAwesomeIcon icon={faPhone} className="contactInfo"/>
                    <h5>PHONE</h5>
                    <p>020-2633211/6, 0722-407607</p>
                </div>

                <div>
                <FontAwesomeIcon icon={faMessage} className="contactInfo"/>
                    <h5>EMAIL</h5>
                    <p>courier@data-rush.com</p>

                </div>

            </div>


        </section>
        <p className='copyRightCont'>(c)2024. Data Rush Services Limited. All Rights reserved</p>




    </div>
  )
}

export default HomePage