import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import { app } from '../firebase.js';
import { getFirestore } from 'firebase/firestore';
import { collection, orderBy, query, where, getDocs } from 'firebase/firestore';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate, useLocation } from 'react-router-dom';

function DelRequestComp() {
    const [studentsList, setStudentList] = useState([]);
    const db = getFirestore(app);
  
    let navigate = useNavigate();
  
    useEffect(() => {
        const fetchData = async () => {


            const q = query(
              collection(db, 'deliveryRequest'),
              orderBy('timestamp', 'desc')
            );
    
            let orders = [];
            const querySnapshot = await getDocs(q);
  
            querySnapshot.forEach((doc) => {
              orders.push({ id: doc.id, ...doc.data() });
            });
  
            // Set your state with the fetched orders
            setStudentList(orders);
           
          
        };
    
        fetchData();
      }, []); // Make sure to include any dependencies if needed
    
  
  
      function viewCustomer(documentId) {

       
      }
  
    return (
      <div className="dataComp">
        <div className="dataCompTop">
          <h5>All Requests</h5>
  
          <div className="searchContainer">
            <FontAwesomeIcon icon={faSearch} className="searchIcon" />
            <input type="text" placeholder="Type to search" />
            <button>Go</button>
          </div>
        </div>
  
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Date</th>
              <th>Delivery Type</th>
              <th>Weight in Kg</th>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {studentsList.map((mystudent) => (
              <tr key={mystudent.id}>              
                <td>{mystudent.timestamp.toDate().toLocaleString()}</td>

                <td>{mystudent.townFrom}</td>
                <td>{mystudent.loadInTonnes}</td>
                <td>{mystudent.userPhone}</td>
                <td>{mystudent.userName}</td>
                <td>
                    <span
                        style={{
                            color:
                              mystudent.status === 'Pending'
                                ? 'red' // Set blue color for 'pending' status
                                : mystudent.status === 'Ongoing'
                                ? 'royalblue' // Set green color for 'Ongoing' status
                                : mystudent.status === 'Delivered'
                                ? 'green' // Set red color for 'delivered' status (or any other status)
                                : 'black', // Set a default color (e.g., black) for any other status
                          }}
                        >
                        {mystudent.status}
                    </span>
                </td>
 
                <td>
                  <button
                    className="btn-sm btn btn-warning"
                    onClick={() => viewCustomer(mystudent.documentId)}
                  >
                    Action
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
export default DelRequestComp