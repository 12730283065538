import React, { useRef, useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { app } from './firebase.js';
import { getFirestore } from '@firebase/firestore';
import { collection, query, getDocs, where, getDoc , setDoc, orderBy, deleteDoc} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import swal from 'sweetalert';
import { useNavigate, useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';

import { doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";


import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Logo from './Images/image001.jpg'


function ViewWaybill() {

    const navigate = useNavigate();
    const location = useLocation();
    const db = getFirestore(app);
    const auth = getAuth();

    const [FullName, setFullName] = useState()
    const [thePhone, setPhone] = useState()

    const [locationTo, setlocationTo] = useState()
    const [locationFrom, setlocationFrom] = useState()

    const [NlocationFromDescription,setNlocationFromDescription ] = useState()
    const [NlocationToDescription,setNlocationToDescription ] = useState()

    const [NlocationFromContactPerson, setNlocationFromContactPerson] = useState();
    const [nlocationFromContactPhone, setnlocationFromContactPhone] = useState();
    const [NlocationToRecepientName, setNlocationToRecepientName] = useState();
    const [NlocationToRecepientPhone, setNlocationToRecepientPhone] = useState();
    const [mysignatureUrl, setsignatureUrl] = useState()

    const [orderType, setorderType] = useState();
    const [NwhatIsBeingDelivered, setNwhatIsBeingDelivered] = useState();
    const [packageDesc, setpackageDesc] = useState();
    const [numberOfItems, setnumberOfItems] = useState();
    const [totalWeight, settotalWeight] = useState();
    const [receivedBy, setreceivedBy] = useState();
    const [receivedByPhone, setreceivedByPhone] = useState();

    const [transactionRef, settransactionRef] = useState();
    // Canvas reference and effect for image processing
    const canvasRef = useRef(null);

    // Receive data from the previous page
    useEffect(() => {
        const data = location.state?.myData;
        setMyData(data);
    }, [location.state]);

    const [myData, setMyData] = useState(null); // State to store the received data
    
    console.log(myData)

        ////

        // Ref for the myPDFReceipt div
    const pdfRef = useRef(null);

    // Function to handle PDF export
    const exportAsPDF = () => {
        html2canvas(pdfRef.current).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgWidth = 210;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            pdf.save(transactionRef +'wabill.pdf');
        });
    };




    useEffect(() => {
        // Fetch driver's location from Firebase when myData changes
        const fetchData = async () => {
          if (myData) {
            try {
              const querySnapshot = await getDocs(query(collection(db, 'Orders'), where('documentId', '==', myData)));
    
              querySnapshot.forEach(async (doc) => {
                const driver = doc.data().driver;  
                const locationTo = doc.data().locationTo;
                const locationFrom = doc.data().locationFrom;

                const NlocationFromDescription = doc.data().NlocationFromDescription;
                const NlocationToDescription = doc.data().NlocationToDescription;
                const NlocationFromContactPerson = doc.data().NlocationFromContactPerson;
                const nlocationFromContactPhone = doc.data().nlocationFromContactPhone;

                const NlocationToRecepientName = doc.data().NlocationToRecepientName;
                const NlocationToRecepientPhone = doc.data().NlocationToRecepientPhone;

                const orderType = doc.data().orderType;
                const NwhatIsBeingDelivered = doc.data().NwhatIsBeingDelivered;
                const packageDesc = doc.data().packageDesc;
                const numberOfItems = doc.data().numberOfItems;
                const totalWeight = doc.data().totalWeight;
                const receivedBy = doc.data().receivedBy;
                const receivedByPhone = doc.data().receivedByPhone;
                const transactionRef = doc.data().transactionRef;
                const signatureUrl = doc.data().signatureUrl;


                setorderType(orderType)
                setNwhatIsBeingDelivered(NwhatIsBeingDelivered)
                setpackageDesc(packageDesc)
                setnumberOfItems(numberOfItems)
                settotalWeight(totalWeight)
                setreceivedBy(receivedBy)
                setreceivedByPhone(receivedByPhone)
                settransactionRef(transactionRef)

                setsignatureUrl(signatureUrl)
                



                setlocationTo(locationTo)
                setlocationFrom(locationFrom)

                setNlocationFromDescription(NlocationFromDescription);
                setNlocationToDescription(NlocationToDescription);

                setNlocationFromContactPerson(NlocationFromContactPerson);
                setnlocationFromContactPhone(nlocationFromContactPhone)
                setNlocationToRecepientPhone(NlocationToRecepientPhone)
                setNlocationToRecepientName(NlocationToRecepientName)

                


              
          
                const j = query(collection(db, "drivers"),where("userId", "==", driver));
                getDocs(j).then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {

                      let full_name = doc.data().full_name;
                      const userphone = doc.data().userphone

                      setFullName(full_name)
                      setPhone(userphone)
                    
             
                    });
                  });



              });
            } catch (error) {
              console.error('Error fetching data from Firebase:', error);
            }
          }
        };
    
        // Initial fetch
        fetchData();
      }, [myData, db]);




  return (
    <div>

<div ref={pdfRef} className="myPDFReceipt">
        <div className="myPDFReceiptImageCont">
                <img src={Logo} alt="" />
                <div className="myPDFReceiptImageContDiv">
                    <p>VAT. NO. 0118190U</p>
                    <p>PIN. NO. P051126800N</p>
                </div>
        </div>
        <div className="myPDFReceiptHead">
            <h2>DATA RUSH SERVICES LIMITED</h2>
            <p>P.O BOX 1012 - 00100 Nairobi. Tel: 020-2633211/6, 0722-407607, E-Mail: courier@data-rush.com</p>
        </div>
        <div className="detailPDFCont">
            <div className="detailPDFLeft">
                <br />
                <h5>From</h5>
                <p>{locationFrom}</p>
                <p>{NlocationFromDescription}</p>
                <p> Contact Person: {NlocationFromContactPerson}</p>
                <p>Contact Phone: {nlocationFromContactPhone}</p>

                <hr />

                <p>Order Type: {orderType}</p>
                <p>What is being delivered: {NwhatIsBeingDelivered}</p>
                <p>Package Desc: {packageDesc}</p>
                <p>number Of Items: {numberOfItems}</p>
                <p>Total Weight: {totalWeight}</p>
                <hr />
                <p>Waybill No: <span className='transactionRef'>{transactionRef}</span></p>

            </div>

            <div className="detailPDFRight">
                <h5>To</h5>
                <p>{locationTo}</p>
                <p>{NlocationToDescription}</p>
                <p> Contact Person: {NlocationToRecepientName}</p>
                <p>Contact Phone: {NlocationToRecepientPhone}</p>

                <hr />

                <p>Received By {receivedBy}</p>
                <p>{receivedBy}'s Phone: {receivedByPhone} </p>  
                <p>Signature: </p>
                <img src={mysignatureUrl}  className='signatureName' alt="" />
                <br />
                
                <hr />
                <p>Receiving Company Stamp</p>

                
                
            </div>

        </div>

    </div>

    <button className='btn btn-primary expbtn' onClick={exportAsPDF}>Export as PDF</button>
    </div>
  )
}

export default ViewWaybill