import React from 'react'
import './Leftbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser} from '@fortawesome/free-solid-svg-icons'
import { faChartPie} from '@fortawesome/free-solid-svg-icons'
import { faLineChart} from '@fortawesome/free-solid-svg-icons'
import { faClipboardCheck} from '@fortawesome/free-solid-svg-icons'
import { faThLarge} from '@fortawesome/free-solid-svg-icons'
import { faTruck} from '@fortawesome/free-solid-svg-icons'
import { faArrowRightFromBracket} from '@fortawesome/free-solid-svg-icons'
import { faFaceSmile} from '@fortawesome/free-solid-svg-icons'
import { faTag} from '@fortawesome/free-solid-svg-icons'
import { faMoneyCheck} from '@fortawesome/free-solid-svg-icons'
import { faFaceAngry} from '@fortawesome/free-solid-svg-icons'
import { faBagShopping} from '@fortawesome/free-solid-svg-icons'
import { faUsers} from '@fortawesome/free-solid-svg-icons'
import { faPenNib} from '@fortawesome/free-solid-svg-icons'
import { faMugHot} from '@fortawesome/free-solid-svg-icons'
import { faChartSimple} from '@fortawesome/free-solid-svg-icons'
import { faClipboard} from '@fortawesome/free-solid-svg-icons'
import { faBriefcase} from '@fortawesome/free-solid-svg-icons'
import { faAddressCard} from '@fortawesome/free-solid-svg-icons'
//routers
import {useNavigate } from "react-router-dom";
import {Link } from "react-router-dom";
import {NavLink } from "react-router-dom";


import {app} from '../firebase.js'
import { getAuth, signOut } from "firebase/auth";


function LeftBar() {

    let navigate = useNavigate();


    function logoutUser(){
        const auth = getAuth();
        signOut(auth).then(() => {
        // Sign-out successful.
            navigate("/")  
        }).catch((error) => {

        });
    }


  return (
    <div className='leftbar'>

    <div className='menuContainer'>

        <div className='logocontainer'>
            <h3>Data Rush</h3>
        </div>

        <br /> <br />
        
        <div className='leftLinkCont d-flex align-items-center' >
            <NavLink to="/dashboard" className='leftNavLinks ' >
                <FontAwesomeIcon icon={faThLarge} className="laftNavIcon"/>
                Dashboard
            </NavLink>
        </div>

        {/* <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/vehicles" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faTruck} className="laftNavIcon"/>
                    Vehicles
                </NavLink>
            </div> */}

            <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/drivers" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faUser} className="laftNavIcon"/>
                    Drivers
                </NavLink>
            </div>

            <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/users" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faAddressCard} className="laftNavIcon"/>
                    Coporate Clients
                </NavLink>
            </div>


            <hr />


        {/* <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/users" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faAddressCard} className="laftNavIcon"/>
                    Users
                </NavLink>
            </div> */}

            

            <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/delRequests" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faFaceSmile} className="laftNavIcon"/>
                    Delivery Request
                </NavLink>
            </div>

            <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/orders" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faClipboardCheck} className="laftNavIcon"/>
                    Orders
                </NavLink>
            </div>

            <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/deliveries" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faClipboardCheck} className="laftNavIcon"/>
                    Completed Deliveries
                </NavLink>
            </div>

            {/* <div className='leftLinkCont d-flex align-items-center'>
                <NavLink to="/pricing-plans" className='leftNavLinks '>
                    <FontAwesomeIcon icon={faTag} className="laftNavIcon"/>
                    Pricing Plans
                </NavLink>
            </div> */}

            <hr />

    </div>

    <div className='footerMenu'>
        <div className='logoutMenu' onClick={logoutUser}>
            <div className='leftLinkCont d-flex align-items-center'>
                <div  className='leftNavLinks logoutbtn'>
                    <FontAwesomeIcon icon={faArrowRightFromBracket} className="laftNavIcon"/>
                    Logout
                </div>
            </div>
        </div>

    </div>





</div>
  )
}

export default LeftBar