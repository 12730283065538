import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import { app } from '../firebase.js';
import { getFirestore } from 'firebase/firestore';
import { collection, orderBy, query, getDocs, doc, setDoc } from 'firebase/firestore';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

function UsersComp() {


  const [modalShowUp, setModalShowUp] = React.useState(false);
  const [studentsList, setStudentList] = useState([]);
  const db = getFirestore(app);


  //fetch all menu items
  useEffect(() => {     
            const fetchData = async () => {  
              const q = query(collection(db, 'clients'), orderBy('signupdate', 'desc'));
            let studentsItem = [];
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                studentsItem.push({ id: doc.id, ...doc.data() });
                setStudentList([...studentsItem]);   
            });
    
        
        };
    fetchData();
    //end magic

    }, []);
    //end fetching menu items


  function viewCustomer() {
    // Implement your viewCustomer function here
  }


  function MyUpdateModal(props) {
    return (
      <Modal
        {...props}
        
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add a Corporate Client
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Company Name</Form.Label>
            <Form.Control type="text" ref={fullName} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Contact Phone Number</Form.Label>
            <Form.Control type="text" ref={phoneNumber} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" ref={emailAddress} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Assigned Password</Form.Label>
            <Form.Control type="password" ref={assPassword} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>City</Form.Label>
            <Form.Control type="email" ref={assCity} />
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button className='btn btn-dark' onClick={props.onHide}>Close</Button>


            <Button variant="primary"  onClick={addNewClient} id='submitBtn'>
            Submit
            </Button>


            <Button variant="primary" disabled id='spinnerbtn' style={{ display : 'none' }}>
                <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                />
                <span> Updating...</span>
            </Button>
            
        </Modal.Footer>
      </Modal>
    );
}


  const fullName = useRef();
  const phoneNumber = useRef();
  const emailAddress = useRef();
  const assPassword = useRef();
  const assCity = useRef();


  function addNewClient(){

    const userFullName = fullName.current.value;
      const userNumber = phoneNumber.current.value;
      const email = emailAddress.current.value;
      const password = assPassword.current.value;
      const userCity = assCity.current.value;
      const timestamp = new Date();

      //temporarily use these but delete. Use state please
      document.getElementById("submitBtn").style.display = "none";
      document.getElementById("spinnerbtn").style.display = "block";


      const auth = getAuth();
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed up 
          const user = userCredential.user;
          // ...
          const reguserid = user.uid;
          const newUserRef = doc(db, 'clients', reguserid);

            setDoc(newUserRef, {

                full_name:userFullName,
                email:email,
                lastTimeLocationUpdate: timestamp,
                city: userCity,
                signupdate: timestamp,
                userType: "client",
                userphone: userNumber,
                userId: reguserid

            }).then(()=>{
               
                window.location.reload();
            })

        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ..
        });

  }



  return (
    <div className="dataComp">
      <div className="dataCompTop">
        <h5>All Clients</h5>

        <div className="searchContainer">
          <FontAwesomeIcon icon={faSearch} className="searchIcon" />
          <input type="text" placeholder="Type to search" />
          <button>Go</button>
          <button onClick={() => setModalShowUp(true)}>Add Corporate Clients</button>
        </div>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Location</th>
            <th>Phone Number</th>
            <th>Email Address</th>
            <th>Sign Up date</th>
            <th>View</th>
            <th>View Orders</th>
          </tr>
        </thead>
        <tbody>
          {studentsList.map((mystudent) => (
            <tr key={mystudent.id}>
              <td>{mystudent.full_name}</td>
              <td>
                {mystudent.city}
              </td>
              <td>{mystudent.userphone}</td>
              <td>{mystudent.email}</td>
              <td>{mystudent.signupdate.toDate().toLocaleString()}</td>
    
              <td>
                <button
                  className="btn-sm btn btn-warning"
                  onClick={() => viewCustomer(mystudent.userId)}
                >
                  View Client
                </button>
              </td>

              <td>
                <button
                  className="btn-sm btn btn-dark"
                  onClick={() => viewCustomer(mystudent.userId)}
                >
                  View Orders
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>


      <MyUpdateModal show={modalShowUp} onHide={() => setModalShowUp(false)} />
    </div>
  );
}

export default UsersComp;
