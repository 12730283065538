import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';
import { getFirestore } from "@firebase/firestore";
import { app } from '../../firebase.js';
import { collection, doc, orderBy, setDoc,query, getDocs, deleteDoc, where, updateDoc} from "@firebase/firestore"; 
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import swal from 'sweetalert';
import Spinner from 'react-bootstrap/Spinner';
import {useNavigate } from "react-router-dom";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

function PlaceOrderComp() {

    const deliveryInstruction = useRef(); 
    const  locationToDescription = useRef();
    const recepientName = useRef();
    const recepientPhone = useRef();
    const describeLocationFrom = useRef(); 
    const whatIsBeingDelivered = useRef(); 
    const contactPersonName = useRef(); 
    const contactPersonPhone = useRef();
 
 
   let navigate = useNavigate();
    const db = getFirestore(app);
    const auth = getAuth();
 
   const [fromLocation, setFromLocation] = useState({ lat: 0, lng: 0 });
   const [toLocation, setToLocation] = useState({ lat: 0, lng: 0 });
   const [fromAddress, setFromAddress] = useState('');
   const [toAddress, setToAddress] = useState('');
 
   const handleFromMarkerDragEnd = (e) => {
     const { latLng } = e;
     const lat = latLng.lat();
     const lng = latLng.lng();
     setFromLocation({ lat, lng });
   };
 
   const handleToMarkerDragEnd = (e) => {
     const { latLng } = e;
     const lat = latLng.lat();
     const lng = latLng.lng();
     setToLocation({ lat, lng });
   };
 
   const handleFromSelect = async (address) => {
     setFromAddress(address);
     try {
       const results = await geocodeByAddress(address);
       const latLng = await getLatLng(results[0]);
       setFromLocation(latLng);
     } catch (error) {
       console.error('Error', error);
     }
   };
 
   const handleToSelect = async (address) => {
     setToAddress(address);
     try {
       const results = await geocodeByAddress(address);
       const latLng = await getLatLng(results[0]);
       setToLocation(latLng);
     } catch (error) {
       console.error('Error', error);
     }
   };
 
 
   function placeRequest(){
 
     const NorderDescription = deliveryInstruction.current.value;
     const NlocationToDescription = locationToDescription.current.value;
     const NlocationToRecepientName = recepientName.current.value;
     const NlocationToRecepientPhone = recepientPhone.current.value;
     const NlocationFromDescription = describeLocationFrom.current.value;
     const NwhatIsBeingDelivered = whatIsBeingDelivered.current.value;
     const NlocationFromContactPerson = contactPersonName.current.value;
     const nlocationFromContactPhone = contactPersonPhone.current.value;
 
     const timestamp = new Date();
 
 
     //temporarily use these but delete. Use state please
     document.getElementById("submitBtn").style.display = "none";
     document.getElementById("spinnerbtn").style.display = "block";
 
     onAuthStateChanged(auth, (user) => {
         if (user) {
         const uid = user.uid;
         // Add a new document with a generated id
         const newTenantRef = doc(collection(db, "Orders"));
         setDoc(newTenantRef, {
 
             NorderDescription: NorderDescription,
             NlocationToDescription: NlocationToDescription,
             NlocationToRecepientName: NlocationToRecepientName,
             NlocationToRecepientPhone:NlocationToRecepientPhone,
 
             NlocationFromDescription:NlocationFromDescription,
             NwhatIsBeingDelivered:NwhatIsBeingDelivered,
             NlocationFromContactPerson:NlocationFromContactPerson,
             nlocationFromContactPhone:nlocationFromContactPhone,
 
             locationTo:toAddress,
             locationFrom:fromAddress,
 
             documentId:newTenantRef.id,
             timestamp: timestamp,
             userId:uid,
             planType: "Customer",
             status: "Pending",
             transactionRef: "H5G41",
             orderType: "Delivery"
         }).then(()=>{
            
             //window.location.reload();
             navigate("/client/all-orders")
         })
 
         } else {
 
         }
     });
 
   }
 
   return (
     <div>
       <Form.Group className="mb-3" controlId="fromLocation">
         <Form.Label>From</Form.Label>
         <PlacesAutocomplete value={fromAddress} onChange={setFromAddress} onSelect={handleFromSelect}>
           {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
             <div>
               <Form.Control {...getInputProps({ placeholder: 'Enter location' })} />
               <div>
                 {loading && <div>.</div>}
                 {suggestions.map((suggestion) => (
                   <div key={suggestion.id} {...getSuggestionItemProps(suggestion)}>
                     {suggestion.description}
                   </div>
                 ))}
               </div>
             </div>
           )}
         </PlacesAutocomplete>
       </Form.Group> 
 
       <Form.Group className="mb-3" controlId="formBasicEmail">
         <Form.Label>Describe the location Above, eg Building, Floor etc</Form.Label>
         <Form.Control type="text" ref={describeLocationFrom} />
       </Form.Group>
 
       <Form.Group className="mb-3" controlId="formBasicEmail">
         <Form.Label>What is being delivered?</Form.Label>
         <Form.Control type="text" ref={whatIsBeingDelivered} />
       </Form.Group>
 
       <Form.Group className="mb-3" controlId="formBasicEmail">
         <Form.Label>Contact Person Name</Form.Label>
         <Form.Control type="text" ref={contactPersonName} />
       </Form.Group>
 
       <Form.Group className="mb-3" controlId="formBasicEmail">
         <Form.Label>Contact person's phone Number</Form.Label>
         <Form.Control type="number" ref={contactPersonPhone} />
       </Form.Group>
       
       <br /> <br />
 
       <Form.Group className="mb-3" controlId="toLocation">
         <Form.Label>To</Form.Label>
         <PlacesAutocomplete value={toAddress} onChange={setToAddress} onSelect={handleToSelect}>
           {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
             <div>
               <Form.Control {...getInputProps({ placeholder: 'Enter location' })} />
               <div>
                 {loading && <div>.</div>}
                 {suggestions.map((suggestion) => (
                   <div key={suggestion.id} {...getSuggestionItemProps(suggestion)}>
                     {suggestion.description}
                   </div>
                 ))}
               </div>
             </div>
           )}
         </PlacesAutocomplete>
       </Form.Group> 
 
       <Form.Group className="mb-3" controlId="formBasicEmail">
         <Form.Label>Describe the location Above, eg Building, Floor etc</Form.Label>
         <Form.Control type="text" ref={locationToDescription} />
       </Form.Group>
 
       <Form.Group className="mb-3" controlId="formBasicEmail">
         <Form.Label>Recepient's Name</Form.Label>
         <Form.Control type="text" ref={recepientName} />
       </Form.Group>
 
       <Form.Group className="mb-3" controlId="formBasicEmail">
         <Form.Label>Recepient's phone Number</Form.Label>
         <Form.Control type="number" ref={recepientPhone} />
       </Form.Group>
 
       {/* <LoadScript googleMapsApiKey="AIzaSyAXT4efPx8AZgOQUr6npAzMtm3H8mm4hWA">
         <GoogleMap
           mapContainerStyle={{ height: '400px', width: '100%' }}
           zoom={2}
           center={{ lat: 0, lng: 0 }}
         >
           <Marker
             position={fromLocation}
             draggable={true}
             onDragEnd={handleFromMarkerDragEnd}
           />
 
           <Marker
             position={toLocation}
             draggable={true}
             onDragEnd={handleToMarkerDragEnd}
           />
         </GoogleMap>
       </LoadScript> */}
 
 
       <br /> <br />
       <Form.Group className="mb-3" controlId="formBasicEmail">
         <Form.Label>Delivery Instructions</Form.Label>
         <Form.Control type="text" ref={deliveryInstruction} />
       </Form.Group>
 
       <br />
       <Button variant="primary"  onClick={placeRequest} id='submitBtn'>Place a delivery</Button>
       <Button variant="primary" disabled id='spinnerbtn' style={{ display : 'none' }}>
                     <Spinner
                     as="span"
                     animation="border"
                     size="sm"
                     role="status"
                     aria-hidden="true"
                     />
                     <span> Placing a request...</span>
                 </Button>
   
     </div>
   )
 }

export default PlaceOrderComp