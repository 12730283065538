import React from 'react'
import LeftBar from '../Components/LeftBar'
import NavBar from '../Components/NavBar'
import DriversComp from './DriversComp'
import './Dash.css'

function Trips() {
  return (
    <div className='contentMain'> 
    <LeftBar/>
    <div className='theRightOne'> 
      <div className='DashTop'>
        <h1>Trips</h1>
        <NavBar/>
      </div>

      <div className='dataComp'>

          <DriversComp/>

      </div>
   </div>
   </div>
  )
}

export default Trips