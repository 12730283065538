import React, { useRef, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch} from '@fortawesome/free-solid-svg-icons'
import Table from 'react-bootstrap/Table';
import {app} from '../firebase.js'
import { getFirestore } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { collection, doc, setDoc,query, getDocs, deleteDoc, where, updateDoc, Timestamp} from "firebase/firestore"; 

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function CustomerComp() {

  const [onDemandData, setOnDemandData] = useState([]);
  const [recurrentData, setRecurrentData] = useState([]);
  const [nextDayData, setNextDayData] = useState([]);

    const db = getFirestore(app);



    useEffect(() => {
      const fetchData = async () => {
        // Fetch data from onDemandSubs collection where expirationDate is in the future
        const onDemandQuery = query(
          collection(db, 'onDemandSubs'),
          where('futureDate', '>', Timestamp.now())
        );
        const onDemandSnapshot = await getDocs(onDemandQuery);
        const onDemandDataArray = [];
        onDemandSnapshot.forEach((doc) => {
          onDemandDataArray.push({ id: doc.id, ...doc.data() });
        });
        setOnDemandData(onDemandDataArray);
  
        // Fetch data from recurrentSubs collection where expirationDate is in the future
        const recurrentQuery = query(
          collection(db, 'recurrentSubs'),
          where('futureDate', '>', Timestamp.now())
        );
        const recurrentSnapshot = await getDocs(recurrentQuery);
        const recurrentDataArray = [];
        recurrentSnapshot.forEach((doc) => {
          recurrentDataArray.push({ id: doc.id, ...doc.data() });
        });
        setRecurrentData(recurrentDataArray);
  
        // Fetch data from nextDaySubs collection where expirationDate is in the future
        const nextDayQuery = query(
          collection(db, 'nextDaySubs'),
          where('futureDate', '>', Timestamp.now())
        );
        const nextDaySnapshot = await getDocs(nextDayQuery);
        const nextDayDataArray = [];
        nextDaySnapshot.forEach((doc) => {
          nextDayDataArray.push({ id: doc.id, ...doc.data() });
        });
        setNextDayData(nextDayDataArray);
      };
  
      fetchData();
    }, []);

        
        


    function viewCustomer(userId){

    }

    function viewPackage(userId){

    }


          

  return (
    <div className='dataComp'>
   

    <Table striped bordered hover>
    <thead>
        <tr>
        <th>Payment Time</th>
        <th>Plan Type</th>
        <th>Amount</th>
        <th>Expiry Date</th>
        <th>Status</th>
        <th>View Package</th>
        <th>View Customer</th>
        </tr>
    </thead>
    <tbody>

    {onDemandData.map((mystudent) => (
      <tr key={Math.random()}>
          <td>{mystudent.paymentTime.toDate().toLocaleString()}</td>
          <td>{mystudent.planType}</td>
          <td>{mystudent.paymentAmount}</td>
          <td>{mystudent.futureDate.toDate().toLocaleString()}</td>
          <td>Active</td>
          <td><button className='btn-sm btn btn-warning' onClick={() => viewPackage(mystudent.userId)}>View Package</button></td>
          <td><button className='btn-sm btn btn-warning' onClick={() => viewCustomer(mystudent.userId)}>View Customer</button></td>
      </tr>
    ))}

    {recurrentData.map((mystudent) => (
      <tr key={Math.random()}>
          <td>{mystudent.paymentTime.toDate().toLocaleString()}</td>
          <td>{mystudent.planType}</td>
          <td>{mystudent.paymentAmount}</td>
          <td>{mystudent.futureDate.toDate().toLocaleString()}</td>
          <td>Active</td>
          <td><button className='btn-sm btn btn-warning' onClick={() => viewPackage(mystudent.userId)}>View Package</button></td>
          <td><button className='btn-sm btn btn-warning' onClick={() => viewCustomer(mystudent.userId)}>View Customer</button></td>
      </tr>
    ))}

    {nextDayData.map((mystudent) => (
      <tr key={Math.random()}>
          <td>{mystudent.paymentTime.toDate().toLocaleString()}</td>
          <td>{mystudent.planType}</td>
          <td>{mystudent.paymentAmount}</td>
          <td>{mystudent.futureDate.toDate().toLocaleString()}</td>
          <td>Active</td>
          <td><button className='btn-sm btn btn-warning' onClick={() => viewPackage(mystudent.userId)}>View Package</button></td>
          <td><button className='btn-sm btn btn-warning' onClick={() => viewCustomer(mystudent.userId)}>View Customer</button></td>
      </tr>
    ))}
        
    </tbody>
    </Table>


</div>
  )
}

export default CustomerComp