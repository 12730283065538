import React from 'react'

function MyDash() {
  return (
    <div>

      <div className='dashAnalysis bg-primary'>
        <h5>My Orders</h5>

          <br /> <br />
        <p>Total Delivered This month: 0</p>
        <p>Total Delivered Today: 0</p>
        <p>Total Pending Today: 0</p>


      </div>

      <div className='dashAnalysis2 bg-warning'>
        <h5>My Invoices</h5>  
        <br /> <br />
        <p>Total Invoices This Month: 0</p>  
        <p>Total Amount This Month: 0</p>  
      </div>

      
    </div>
  )
}

export default MyDash