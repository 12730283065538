import React, { useRef, useState } from 'react'
import logo from './Images/image001.jpg'
import fbIcon from './Images/fbicon.svg'
import twitterIcon from './Images/twittericon.svg'
import {Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone} from '@fortawesome/free-solid-svg-icons'
import Form from 'react-bootstrap/Form';
import servOneImg from './Images/download (6).png'
import servTwoImg from './Images/download (3).png'
import servThreeImg from './Images/download (4).png'
import servFourImg from './Images/download (5).png'
import {useNavigate } from "react-router-dom"; 
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import axios from 'axios'; // Import axios
import { getFirestore } from "firebase/firestore";
import swal from 'sweetalert';

import {app} from './firebase.js'
import { collection, orderBy, query, getDocs, doc, setDoc  } from 'firebase/firestore';

import Alert from 'react-bootstrap/Alert';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';


function NewHome() {

    let navigate = useNavigate();
    const db = getFirestore(app);
    const [showProgress, setShowProgress] = useState(false);
    const [showSignInbtn, setShowSignInbtn] = useState(true);

    const [fromLocation, setFromLocation] = useState({ lat: 0, lng: 0 });
  const [toLocation, setToLocation] = useState({ lat: 0, lng: 0 });
  const [fromAddress, setFromAddress] = useState('');
  const [toAddress, setToAddress] = useState('');

    const townFrom = useRef();
    const townTo = useRef();
    const loadInTonnes = useRef();
    const userPhone = useRef();
    const userName = useRef();


    const handleFromSelect = async (address) => {
        setFromAddress(address);
        try {
          const results = await geocodeByAddress(address);
          const latLng = await getLatLng(results[0]);
          setFromLocation(latLng);
        } catch (error) {
          console.error('Error', error);
        }
      };
      const handleToSelect = async (address) => {
        setToAddress(address);
        try {
          const results = await geocodeByAddress(address);
          const latLng = await getLatLng(results[0]);
          setToLocation(latLng);
        } catch (error) {
          console.error('Error', error);
        }
      };


    function signIntoAccount(){
        navigate("/login");
    }


    function submitRequest(){

        setShowProgress(true);
        setShowSignInbtn(false);

        const theuserName = userName.current.value;
        var formattedNumber = formatPhoneNumber(theuserName);

        var totalAmount = '400' 
      
        sendStkPush(formattedNumber, totalAmount).finally(() => {
             setShowProgress(false); // Hide loading spinner after request completion
          });


    }


    const sendStkPush = async (phone, amount) => {
        try {
          const response = await axios.post('https://us-central1-pepea-a9eaa.cloudfunctions.net/datampesa/stkpush', {
            phone,
            amount
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
    
          const data = response.data;
          console.log('STK Push Response:', data);
    
          if (data.ResponseCode === '0') {
            // STK Push sent successfully
            swal({
              title: 'STK Push Sent',
              text: data.CustomerMessage,
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'I have paid',
                  value: 'confirm',
                  className: 'confirm-button'
                },
                cancel: 'I haven\'t paid'
              },
              content: {
                element: 'div',
                attributes: {
                  innerHTML: '<style>.confirm-button { position: relative; }</style>'
                }
              }
            }).then((value) => {
              if (value === 'confirm') {
                 setShowProgress(true); // Show loading spinner on the "I have paid" button
    
                verifyPayment(data.CheckoutRequestID).then(() => {
                   setShowProgress(false); // Hide loading spinner after payment confirmation
                });
              } else {
                swal('Please complete the payment and try again.');
              }
            });
          } else {
            swal('Failed to send STK Push Request');
          }
        } catch (error) {
          console.error('Error sending STK Push request:', error);
          swal('Error sending STK Push Request. Please try again.');
        }
    };

    const verifyPayment = async (checkoutRequestId) => {
        try {
          const response = await axios.post(
            'https://us-central1-pepea-a9eaa.cloudfunctions.net/datampesa/query',
            {
              queryCode: checkoutRequestId  // Ensure correct field name here
            },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
      
          const data = response.data;
          console.log('Payment Verification Response:', data);
      
          if (data.ResultCode === '0') {


            const thetownFrom = townFrom.current.value;
            //const thetownTo = townTo.current.value;
            const theloadInTonnes = loadInTonnes.current.value;
            const theuserPhone = userPhone.current.value;
            const theuserName = userName.current.value;
            const timestamp = new Date();
            setShowProgress(true);
            setShowSignInbtn(false);
    
            var formattedNumber = formatPhoneNumber(theuserName);
    
            const newUserRef = doc(collection(db, "deliveryRequest"));
            setDoc(newUserRef, {
                townFrom:thetownFrom,
                //townTo:thetownTo,
                timestamp: timestamp,
                loadInTonnes: theloadInTonnes,
                userPhone: theuserPhone,
                userName: theuserName,
                docId:newUserRef.id,
                status:"Pending",
                fromLocation: fromAddress,         // Store the fromLocation (latitude and longitude)
                toLocation: toAddress,
                lat: fromLocation,
                lng: toLocation
         // Store the toLocation (latitude and longitude)
            }).then(()=>{
                //send sms here
                //use this as phone number (theuserPhone)
                //message be (Dear theuserName, your delivery request has been made successfully. We will get back to you)

                const phoneNumber = "+" + formattedNumber;
                const message = 'Hello ' + theuserPhone + ', your delivery request has been made successfully. We will get back to you. For any clarifications please reach us on 0722407607';
                 
                try {
                    const response = axios.post('https://us-central1-pepea-a9eaa.cloudfunctions.net/datasms/send-sms', {
                        phoneNumber,
                        message
                    });
                    console.log('Message sent successfully:', response.data);
                    swal("Successful!", "Delivery request made successfully, We will get back to you", "success");
                    setShowProgress(false);
                    setShowSignInbtn(true);
                
                } catch (error) {
                    console.error('Error sending message:', error.message);
                    swal("Successful!", "Delivery request made successfully, We will get back to you", "success");
                    setShowProgress(false);
                    setShowSignInbtn(true);
                }
            })
    
           
            

    
          } else {
            swal('Payment Not Verified', 'Your payment could not be verified.', 'error');
          }
    
        } catch (error) {
          console.error('Error verifying payment:', error);
          swal('Error verifying payment. Please try again.');
        }
      };
    

    function formatPhoneNumber(phoneNumber) {
        // Remove any non-digit characters from the phone number
        var digitsOnly = phoneNumber.replace(/\D/g, '');
        
        // If the number starts with '0', replace it with '254'
        if (digitsOnly.startsWith('0')) {
            digitsOnly = '254' + digitsOnly.substring(1);
        }
        
        return digitsOnly;
    }

  return (
    <div className='mainMainAccounPage'>

        <div className='theNavBar'>
            <img src={logo} className='theLogo' alt="" />
            <ul className='themenuPage'>
                <li><a href="#home" className='menuItems'>Home</a></li>
                <li><a href="#about" className='menuItems'>About Us</a></li>
                <li><a href="#services" className='menuItems'>Our Services</a></li>
                <li><a href="#ourTeam" className='menuItems'>Our Team</a></li>
                <li><a href="#contact" className='menuItems'>Contact Us</a></li>
 
                <li>
                <Link to="/login" className='thenavToHome'>Track your Order</Link>
                </li>
            </ul>

            <Link to="/login" className='thenavToHome2'>Track your Order</Link>

        </div>



        <section className='Hero'>
            <div className='theTriangle'>
                <h4>DATA RUSH KENYA</h4>
                <h1>Delivery with a personal touch</h1>
                <p>
                To provide the most reliable and consistent courier services in the region. This shall be achieved by providing exceptional customer service and staying focused on our customers’ needs and demands, thereby giving them satisfaction and value for money
                </p>
                <div className='bottomContactCont'>
                    <button onClick={signIntoAccount}>Sign in to your account</button>
                    <div className='contactCont'>
                        <FontAwesomeIcon icon={faPhone}  className='phoneCont'/>
                        <div className='callUsCont'>
                            <h6>Call Us</h6>
                            <h5>0722407607</h5>
                        </div>
                    </div>
                </div>
                

            </div>
        </section>


        <section className='section34' id='about'>
          
            <div className='section34A sectionImageContBBB'>
                <div className='section34AImage1'></div>
                <div className='section34AImage2'></div>
            </div>
            <div className='section34B'>
                <p>ABOUT US</p>
                <h1>Fast Moving World of Business</h1>
                <p>
                Data Rush Services Limited commenced operations in June 1997. The company offers courier services to the Kenyan business Community within Nairobi and its environs plus between Nairobi – (Full operational branches) , Mombasa, and Kisumu.
                </p>
                <p>In this fast-moving world of business, you need a fast-moving delivery company. At DATA RUSH SERVICES we collect and deliver your personal/official correspondence to business/clients; letters, parcels, monthly statements, invoices, advertisements fliers, cheques, medical reports, and all other important documents and packages on time, all the time. In addition, we adhere to save your precious time by running your monthly errands of paying Telephone, Electricity, and Water bills. Our goal is to compliment Speed (Rush) with customer care and service.</p>
                <p>Our services cater for picking up consignments from your premises twice or once a day depending on your requirements and delivering them to the recipient at the shortest time possible: we guarantee it. We are also on call for your Urgent/Priority deliveries, which we ably deliver within three hours due to the traffic jams. All you have to do is pick-up the telephone and call us, and we will be at your premises within the shortest time possible.</p>
                <p>We are ideally located in Upper hill Nairobi, which is an ideal focal point to reach any corner of Nairobi. We operate a Fleet of 65 Motorcycles and 20 Vehicles covering the entire City. Our branches offers door-to-door delivery and collection services for mail or parcels leaving or arriving at the each branch. If you have mail or parcels destined for the major towns , call Data Rush Services, we will be happy to receive your call.</p>

            </div>
        </section>

        <section className='section35'>
            <div className='section35A'>
                <h5>WHY CHOOSE US?</h5>
                <h1>Letting you concentrate on your core line of business</h1>
                <ul>
                    <li>Less Capital Investment: You will not be obligated to buy motorcycles or Motor Vehicles to do your deliveries.</li>
                    <li>Reduced cost to your Company: you will make a saving since you won’t be paying salaries, medical covers, house allowance, fuel, maintenance, and other unnecessary administration cost.</li>
                    <li>Our Services will help your budget and plan your costs more accurately.</li>
                    <li>OUR CLIENT will be able to concentrate on its core line of business without having to worry about any deliveries.</li>
                    <li>Our Company never goes on leave or strike; in cases of emergency we always have stand-by motorbikes and riders, we are always reliable.</li>
                    <li>We give good value for your money.</li>
                    <li>We compliment speed (RUSH) by getting your documents to their Destination ON TIME, ALL THE TIME; we handle all documents with priority, customer care, and give tailor-made service to suit every need.</li>
                </ul>
            </div>
            <div className='section35B'>
                

                <div className='sendRequestCard'>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Delivery Type</Form.Label>
                            <Form.Select aria-label="Default select example" ref={townFrom}>
                                <option value="Within Nairobi">Within Nairobi</option>
                                <option value="Mobasa to Noirobi">From Mombasa to Nairobi</option>
                                <option value="Nairobi to Mombasa">From Nairobi to Mombasa</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="fromLocation">
                        <Form.Label>Select pick up location</Form.Label>
                        <PlacesAutocomplete value={fromAddress} onChange={setFromAddress} onSelect={handleFromSelect}>
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div >
                            <Form.Control {...getInputProps({ placeholder: 'Enter location' })} />
                            <div className='pickupDivsc'>
                                {loading && <div>.</div>}
                                {suggestions.map((suggestion) => (
                                <div key={suggestion.id} {...getSuggestionItemProps(suggestion)}>
                                    {suggestion.description}
                                </div>
                                ))}
                            </div>
                            </div>
                        )}
                        </PlacesAutocomplete>
                        </Form.Group> 

                        <Form.Group className="mb-3" controlId="toLocation">
        <Form.Label>To</Form.Label>
        <PlacesAutocomplete value={toAddress} onChange={setToAddress} onSelect={handleToSelect}>
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <Form.Control {...getInputProps({ placeholder: 'Enter location' })} />
              <div className='pickupDivsc'>
                {loading && <div>.</div>}
                {suggestions.map((suggestion) => (
                  <div key={suggestion.id} {...getSuggestionItemProps(suggestion)}>
                    {suggestion.description}
                  </div>
                ))}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </Form.Group> 

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                            <Form.Label>Weight in Kgs</Form.Label>
                            <Form.Select aria-label="Default select example" ref={loadInTonnes}>
                                <option value="1">Below 5Kg</option>
                            </Form.Select>
                        </Form.Group>

                    <div>
                    
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                            <Form.Label>Your Name</Form.Label>
                            <Form.Control type="text" placeholder="" ref={userPhone} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                            <Form.Label>Your Phone Number</Form.Label>
                            <Form.Control type="number" placeholder="" ref={userName} />
                        </Form.Group>

                        <Alert >
                            Delivery Fee: KES. 400
                        </Alert>

                        <br />

                        {showSignInbtn && 
                            <button onClick={submitRequest}>Submit Request</button>
                        }

                        {showProgress && 
                            <Button  disabled >
                                <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                                <span> Submitting Request...</span>
                            </Button>
                        }

                        <br/> <br/>

                    </div>

                
                </div>

            </div>
        </section>



        <section className='section36' id='services'>

            <h5>Our Services</h5>
            <h1>Tailor Made Services to suite your Requirements</h1>

            <div className='section36A'>
                <div className='section36ATop'>
                </div>
                <h5>Normal Mail Service</h5>
                <p>In this option, we send our courier to OUR CLIENT at specified times daily depending on your requirements, to deliver or collect items for same day expedition.</p>
            </div>

            <div className='section36A'>
                <div className='section36BTop'>
                </div>
                <h5>Temporary Call-In Service</h5>
                <p>This service is offered to those who do not have an account with us. Clients can call for service on “in-need” basis. This service is suitable to individuals or institutions with low volume of mail
                </p>
            </div>

            <div className='section36A'>
                <div className='section36CTop'>
                </div>
                <h5>Urgent Service</h5>
                <p>
                    
                This is a time sensitive service where deliveries are done within one three
hours from the time we pick from the consignor and the time it is delivered to Tailor the consignee. All the clients enjoy this option and are welcome to call at any Made time Monday through Friday.
                    </p>
            </div>

            

            <div className='section36A'>
                <div className='section36DTop'>
                </div>
                <h5>Banking and Documentation</h5>
                <p>
                Our bank services include collection and delivery of your Bank documents and deposit of cheques. Documentation includes processing of IDFs, CRFs, Licenses, Air tickets and payment of bills.
                    </p>
            </div>

            <div className='section36A'>
                <div className='section36ATop'>
                </div>
                <h5>Internal Office Mail</h5>
                <p>
                A Messenger is assigned to collect, co-ordinate, and log-in all mail sent within or outside your office premises. His duty can also include photocopying and running short errands. This service is offered both on short and long-time period basis.
                    </p>
            </div>

            

            <div className='section36A'>
                <div className='section36ETop'>
                </div>
                <h5>Tailor-Made Services</h5>
                <p>
                    
                Data Rush Services core competence is our ability to offer our clients’ courier service that best suit your requirement. Given the diversity service needs, we always work-out a service plan that will serve your needs best.
                    </p>
            </div>


            <div className='section36A'>
                <div className='section36FTop'>
                </div>
                <h5>Mail Shot</h5>
                <p>
                When you want to reach more than 10,000 people by sending them Mail,
Magazines or any advertising material, we collate the material, stuff them Operations in envelopes print labels and post them for you within three days.
                </p>
            </div>

            <div className='section36A'>
                <div className='section36CTop'>
                </div>
                <h5>Suite Dedicated Service</h5>
                <p>
                A motorbike Courier is assigned to a specific client on a daily basis. The Requireme Courier reports to your office at 8:00 a.m. and will be at your service
nts throughout the day up to 5:00 p.m. He will be armed with a communication
gadget to enable him communicate with our office and hence track his movements all day. For this service, Data Rush Services guarantees a backup Courier and Motorbike in case of any breakdown to ensure a smooth operation.
                    </p>
            </div>

            <div className='section36A'>
                <div className='section36ATop'>
                </div>
                <h5>Bulk Mail</h5>
                <p>
                This includes mail for functions such as, launches, banquets, party invitations, annual reports, bulletins, statements, invoices and other company events. Data Rush will collect any of the above from YOU offices and make sure they are delivered on time.
These are the services offered when parcels/mails have to leave our Nairobi offices to our regional offices in the country (upcountry) to various clients.
                    </p>
            </div>


        </section>

        <div className='clearFix'></div>

        <section className='Operations'>
            <h1>Operations</h1>
            <p>We have a fleet of motorbikes 65 in total that work around Nairobi city and its environs. We also have vehicles 13 trucks and 7 vans for delivery of parcels within Nairobi, Mombasa, Thika, Athi River and Kisumu.
           <ul>
                <li> 0.75 Tonne Van</li>
                <li>1 Tonne Van</li>
                <li>3 Tonne Truck </li>
                <li>5 Tonne Truck </li>
                <li>7 Tonne Truck </li>
                <li>10 Tonne Truck</li>
           </ul>

            In order to ensure that accurate feedback is available upon request, we have designed waybills that give detailed information on the delivery of any letter or parcel collected from your premises. We also have tailor made services to meet individual/client needs. We provide a collection book that is used to record all mail collected from client’s premises.
            We also have a mail management system where our customers can be able to track mails and parcels that they have dispatched or have been sent a package and are aware of the package details.</p>

        </section>


        <section className='services' >

<h1>Our Clientele</h1>
<div className='services1'>
    
    <div>
        <h3>Banks</h3>
        <p>
        The World Bank, Housing Finance, Co-operative Bank of Kenya, Commercial Bank of Africa, Equity Bank, Faulu Kenya, Apex Investment Bank, IDB Capital, IFC.
            </p>
    </div>
    <img src={servOneImg} alt="" />

</div>

<div className='services1'>
<img src={servTwoImg} alt="" />
    <div>
        <h3>Large Corporates</h3>
        <p>
        Total Kenya Limited, Bollore Logistics, Kenya Airways, UAP Old Mutual, ICEA Lion Group, Davis & Shirtliff, E.A Cables, KPMG, Agility Logistics, Aga Khan Health & Education, KTDA, Serena Beach, Unilever Kenya,DHL, Jumia Services,Bomu Hospital Cargil
        </p>
    </div>
    

</div>

<div className='services1'>
    
    <div>
        <h3>SMEs</h3>
        <p>
        Weetabix, Alvi Trading, Pharmaken, , Nairobi Sports House,Kate Freight,Amiran Communication,Shamas Motors,General Automobile, Ensing Autos Ltd, Transfreight Logistics, Marks Enterprise, Kalon Capital.
            </p>
    </div>
    <img src={servThreeImg} alt="" />

</div>

<div className='services1'>
<img src={servFourImg} alt="" />
    <div>
        <h3>NGOs</h3>
        <p>
        UNICEF Kenya, UNICEF Protocol, UNDP, IFRC
            </p>
    </div>
    

</div>

</section>

<section className='ourTeam' id='ourTeam'>
    <h1>Our Team</h1>
    <br />


    <div className='theTeam'>
        <div className='theTeamTop'>

        </div>
        <h5>Mrs. Eva M. Njukia</h5>
        <p>Managing Director</p>
    </div>

    <div className='theTeam'>
        <div className='theTeamTop'>

        </div>
        <h5>Mr. Njukia Kiuri</h5>
        <p>Director</p>
    </div>

    <div className='theTeam'>
        <div className='theTeamTop'>

        </div>
        <h5>Ms. Susan Makena Mutema</h5>
        <p>General Manager</p>
    </div>

    {/* <div className='theTeam'>
        <div className='theTeamTop'>
        </div>
        <h5>Mr. G. N. Murigu</h5>
        <p>Upcountry Manager</p>
    </div> */}

    


</section>
<div className='clearFix'></div>

<h1 className='h1Out' id='contact'>Contact Us</h1>
<section className='newContact' >
    <div className='newContactOne'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63820.96012512795!2d36.74027639176742!3d-1.2881540322909626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f11a283d51c0d%3A0xc7b9d2f0cbeda746!2sData%20Rush%20Services%20Ltd-Nairobi%20Office!5e0!3m2!1sen!2ske!4v1710513737068!5m2!1sen!2ske"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <div className='newContactTwo'>

        <p>
            Nairobi Office <br />
            Data Rush Services Limited, <br />
            Mawenzi Garden, Off Matumbato Rd Upper Hill,<br />
            P. O. Box 10102, 00100 Nairobi, <br />
            Tel: 020 2633211, 020 2633216<br />
            Fax: 254-2-2711593<br />
            0722407607<br />
            Email: courier@data-rush.com <br />
            Website: www.data-rush.com<br /><br />

            Mombasa Branch <br />
            Cathedral Street,<br />
            Lotus Plaza, Mombasa<br />
            Tel: 0748227488<br /> <br />

            Kisumu Branch contact person<br />
            Chaganlal Building<br />
            Opposite Swan Centre, Oginga Odinga Street <br />
            Tel: 0701029055
        </p>

        <div className='socialMediaIcons'>
            <a href="https://www.facebook.com/p/Data-Rush-Services-Limited-100063684123131/">
                <img src={fbIcon} alt="" />
            </a>
            
            <a href="https://twitter.com/datarushservice">
                <img src={twitterIcon} alt="" />
            </a>
        </div>

    </div>
</section>








    </div>
  )
}

export default NewHome